import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { cancelOrder } from '../../../services/ReportService';
import { useSelector, useDispatch } from "react-redux";
import { CANCELLATION_SUCCESS_SERVER_CODE } from '../../../utils/Constants';

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  header: {
    fontWeight: "bold",
    marginBottom: "1em"
  }
};

export default function CancellationModal({cancellationState, setCancellationState, handleClose, partnerName}) {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch();
  const [isCancellingOrder, setIsCancellingOrder] = useState(false)

  const handleCancellation = async (logisticsId, user, dispatch) => {
    setIsCancellingOrder(true)
    const response = await cancelOrder(logisticsId, user, dispatch)
    if(response?.code === CANCELLATION_SUCCESS_SERVER_CODE) {
      handleClose()
      setCancellationState((prevState) => ({...prevState, isCancelled: true}))
    }
    setIsCancellingOrder(false)
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={cancellationState?.isCancelModalOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={cancellationState?.isCancelModalOpen}>
          <Box sx={styles}>
            <Typography id="transition-modal-title" variant="h6" component="h2" style={styles.header}>
                Are you sure the logistics order has been cancelled on {partnerName}'s side? This cancellation only for LMS and CRMZ
            </Typography>
            <Grid
              container
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button variant="outlined" onClick={handleClose}>Close</Button>
              <Button 
                variant="contained"
                disabled={isCancellingOrder}
                endIcon={isCancellingOrder ? <CircularProgress color='inherit' size={20} /> : <ArrowForward />}
                onClick={() => {
                  handleCancellation(cancellationState?.logisticsId, user, dispatch)
                }}
              >Cancel</Button>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}