import {  createSlice } from "@reduxjs/toolkit";


export const RoleSlice = createSlice({
  name: 'role',
  initialState: {
    commandCenterRole: false
  },
  reducers: {
    setRole: (state, action) => {
      state.commandCenterRole = action.payload;
    },
  }
});

export const setCommandCenterRole = (state) => state.role.commandCenterRole;

export const {setRole} = RoleSlice.actions;
export default RoleSlice.reducer;