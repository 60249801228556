import { get } from "../utils/ApiUtils";
import { LS_DRIVER_COORDINATES_PATH, LS_MAP_MARKERS_PATH } from "../utils/Constants"
import { getAuthHeaders } from "./AuthService";
import { useDispatch, useSelector } from "react-redux";

export const useCommandCenterService = () => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const getMapMarkers = async (onlyDriverMarkers) => {
        
        let url = onlyDriverMarkers ? `${process.env.REACT_APP_SERVER_URL}${LS_MAP_MARKERS_PATH}?markerType=Driver` : `${process.env.REACT_APP_SERVER_URL}${LS_MAP_MARKERS_PATH}`
        let accessToken = user.accessToken;

        let authHeaders = await getAuthHeaders(accessToken);
        
        const response = await get(url,
            {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": '*',
            ...authHeaders
            }, dispatch, user
        );

        if(onlyDriverMarkers){
            const createDriverDatapointsAndRequiredARCsResponse = createDriverDatapointsAndRequiredARCs(response.result.Driver);
            
            return createDriverDatapointsAndRequiredARCsResponse;
        }
        else{
            let arcMarkersMap = {};

            const createDriverDatapointsAndRequiredARCsResponse = createDriverDatapointsAndRequiredARCs(response.result.Driver);
            
            for(let i in response.result.ARC){
                let current = response.result.ARC[i];

                arcMarkersMap[current.id] = createDatapoint(current);

            }

            createDriverDatapointsAndRequiredARCsResponse['arcMarkersMap'] = arcMarkersMap;
            //console.log("arcMarkersMap => ", JSON.stringify(arcMarkersMap));

            return createDriverDatapointsAndRequiredARCsResponse;
        }
    }

    const scheduleUpdateCoordinates = async () => {
        let url = `${process.env.REACT_APP_SERVER_URL}${LS_DRIVER_COORDINATES_PATH}`
        let accessToken = user.accessToken;
    
        let authHeaders = getAuthHeaders(accessToken);
        
        const response = await get(url,
            {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": '*',
              ...authHeaders
            }, dispatch, user
          );
    
        console.log("updateCoordinates => ", response);
    
        return true;
    }

    return {
        getMapMarkers,
        scheduleUpdateCoordinates
    }
}

const createDatapoint = (marker) => {
    
    if(marker.markerType==='Driver'){
        return {
            id : `${marker.id} ${marker.coordinates.lat} ${marker.coordinates.lng}`,
            type: 'Driver',
            content : createDriverMarker(marker),
            position :  marker.coordinates,
            icon: "https://d1hqkkn4x0x6dy.cloudfront.net/driver.png"
        }
    }else{
        return {
            id : marker.id,
            type: 'ARC',
            content : createARCMarker(marker),
            position :  marker.coordinates,
            icon: "https://d1hqkkn4x0x6dy.cloudfront.net/repairCenter.png"
        }
    }
}

const createDriverMarker = (driverData)=>{
    let url = driverData.logisticsTrackingLink;

    return (`<div class='bubble' style='width:200px;'><p>Partner Order id: <a href=${url}>${driverData.orderId}</a><p>Driver name: ${driverData.driverName}</p><p>Driver phone: ${driverData.driverPhone}</p><p>Status: ${driverData.status}</p></p><p>Delivery partner: ${driverData.deliveryPartnerName}</p></div>`)
}

const createARCMarker = (arcData)=>{
    return (`<div class='bubble' style='width:200px;'><p>Arc name: ${arcData.name}</p><p>Address: ${arcData.address}</p><p>Phone number: ${arcData.phoneNumber}</p></div>`)
}

const createDriverDatapointsAndRequiredARCs = (drivers) => {
    let response = {};
    let driverDatapoints = [];
    //let requiredArcs = new Set();
    let requiredArcs = {};
        
    for(let i in drivers){
        let current = drivers[i];

        driverDatapoints.push(createDatapoint(current));

        if(current.deliveryType === "Customer Pickup"){
            for(let j in current.dropoff){
                let curr = current.dropoff[j];

                //requiredArcs.add(curr.id);

                if(requiredArcs.hasOwnProperty(curr.id)){
                    requiredArcs[curr.id].incoming = requiredArcs[curr.id].incoming ? requiredArcs[curr.id].incoming += 1 : 1;
                }
                else{
                    requiredArcs[curr.id] = {
                        incoming: 1,
                        outgoing: 0
                    }
                }
            }
        }
        else{
            //requiredArcs.add(current.pickup.id);

            if(requiredArcs.hasOwnProperty(current.pickup.id)){
                requiredArcs[current.pickup.id].outgoing = requiredArcs[current.pickup.id].outgoing ? requiredArcs[current.pickup.id].outgoing += 1 : 1;
            }
            else{
                requiredArcs[current.pickup.id] = {
                    incoming: 0,
                    outgoing: 1
                }
            }
        }
    }
    response.driverDatapoints = driverDatapoints;
    response.requiredArcs = requiredArcs;

    return response;
}