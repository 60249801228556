export const getRouteOnPop = (url) => {
    let _splits = url.includes('http://localhost:3000') ? url.split('http://localhost:3000') : url.split(process.env.REACT_APP_SERVER_URL)
    return _splits[1];
}

export const isString = (myVar)=>{
    if (typeof myVar === 'string' || myVar instanceof String)
    return true; 
    else return false;
}

export const removeAppBaseUrlForTracking = (url)=>{
    let finalString = url;
    let firstIndexOfTrack = url.indexOf('track/');
    finalString = url.substring(firstIndexOfTrack, url.length)
    return finalString;
}
