import { Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField, Tooltip, FormControlLabel, Radio } from "@mui/material";
import { useRef, useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from "@mui/x-date-pickers";
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import { enqueueSnackbar } from "notistack";
import { FILTER_ERROR_MESSAGE } from "../../../utils/StringConstants";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { checkIsPhoneNumber, getFilterStateKeyByFilterType, isAllFilterFilled, isAnyFilterSelected } from "../util";
import { isString } from "../../../utils/Utils";
import { defaultTheme } from "../../../styles/Theme";
import SearchIcon from '@mui/icons-material/Search';

const styles = {
  parentComponent: {
    width: '100%',
  },
  searchTextField: {
  },
  searchSelectField: {
  },
  addFilterButton: {
    marginTop: '10px'
  },
  addFilterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 1,
    bgcolor: defaultTheme.palette.primary.contrastText,
    borderRadius: 1,
  },
  menuItemBox: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 1,
    m: 0,
    marginTop: -5,
    bgcolor: defaultTheme.palette.primary.contrastText,
    borderRadius: 1,
  },
  filterStateBox: {
    display: 'flex',
    justifyContent: 'space-evenly',
    p: 1,
    m: 1,
    bgcolor: defaultTheme.palette.primary.contrastText,
    borderRadius: 1,
    marginTop: '30px'
  }
};


function DashboardFilter(props) {

  const { distinctSearchFilters } = props;
  const searchBoxRef = useRef();
  const filter = { singleFieldSearch: 'singleFieldSearch', country: 'country', orderStatus: 'orderStatus', carriers: 'carriers', productType: 'productType', dateTo: 'dateTo', dateFrom: 'dateFrom', cancellationParty: 'cancellationParty', cancellationReason: 'cancellationReason', deliveryType: 'deliveryType' }
  const singleFieldKeys = { 'CRM ID': 'claimId', 'Carrier Order ID': 'deliveryPartner.orderId', 'Source Name': 'pickup.name', 'Source Phone': 'pickup.phoneNumber', 'Source Address': 'pickup.address', 'Recipient Name': 'dropoff.name', 'Recipient Phone': 'dropoff.phoneNumber', 'Recipient Address': 'dropoff.address', 'Notes': 'dropoff.notes' }
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterSequence, setFilterSequence] = useState([])
  const open = Boolean(anchorEl);
  const countries = distinctSearchFilters.countries;
  const orderStatus = distinctSearchFilters.statuses;
  const carriers = distinctSearchFilters.carriers;
  const productType = distinctSearchFilters.claimTypes;
  const cancellationParty = distinctSearchFilters.cancellationParties;
  const deliveryTypes = distinctSearchFilters.deliveryTypes;
  const [selectedOption, setSelectedOption] = useState('Carrier Order ID');
  const [filterState, setFilterState] = useState({
    countryFilter: {
      show: false,
      value: []
    },
    orderStatusFilter: {
      show: false,
      value: []
    },
    orderDateFilter: {
      show: false,
      valueTo: 0,
      valueFrom: 0
    },
    carrierFilter: {
      show: false,
      value: []
    },
    productTypeFilter: {
      show: false,
      value: []
    },
    cancellationPartyFilter: {
      show: false,
      value: []
    },
    cancellationReasonFilter: {
      show: false,
      value: ''
    },
    deliveryTypeFilter: {
      show: false,
      value: []
    },
    singleFieldSearchFilter: {
      show: false,
      key: '',
      value: '',
    }
  })

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setDate = (_filter, _value) => {
    let newFilterState = {
      ...filterState
    }
    if (_filter === filter.dateFrom) {
      let key = getFilterStateKeyByFilterType(_filter)
      try {
        const startOfDay = new Date(_value);
        startOfDay.setUTCHours(0, 0, 0, 0);
        newFilterState[key] = {
          ...newFilterState[key],
          valueFrom: startOfDay.getTime()
        }
      } catch (ex) {
        console.error('Date Exception from : ', ex)
        newFilterState[key] = {
          ...newFilterState[key],
          valueFrom: 0
        }
      }
    } else if (_filter === filter.dateTo) {
      console.log(_value);

      try {
        if (_value === null) {
          throw Error("Invalid Date")
        }
        const endOfDate = new Date(_value)
        endOfDate.setUTCHours(23, 59, 59, 999);
        newFilterState = {
          ...filterState,
          orderDateFilter: {
            ...filterState.orderDateFilter,
            valueTo: endOfDate.getTime()
          }
        }
      } catch (ex) {
        console.error('Date Exception to : ', ex)
        newFilterState = {
          ...filterState,
          orderDateFilter: {
            ...filterState.orderDateFilter,
            valueTo: 0
          }
        }
      }
    }
    setFilterState(newFilterState)
  }

  const setListItems = (_filter, _value) => {
    let newFilterState = {
      ...filterState
    }
    let filterStateKey = getFilterStateKeyByFilterType(_filter)
    console.log(" is array  = ", Array.isArray(_value))
    let duplicatedIds = {}
    let singleArrValues = []
    _value.forEach((item) => {
      if (duplicatedIds.hasOwnProperty(item.id)) {
        duplicatedIds[item.id] = true
      } else {
        duplicatedIds[item.id] = false
      }
    });
    _value.forEach((item) => {
      if (duplicatedIds[item.id] === false) {
        singleArrValues.push(item)
      }
    });
    newFilterState[filterStateKey].value = singleArrValues;
    console.log("newFilterState => ", newFilterState)
    setFilterState(newFilterState)
  }

  const setSingleValueFilter = (_filter, _value) => {
    let newFilterState = {
      ...filterState
    }

    let filterStateKey = getFilterStateKeyByFilterType(_filter);

    if (_filter === filter.singleFieldSearch) {
      let _key = singleFieldKeys[selectedOption]
      newFilterState[filterStateKey].key = _key
      if (checkIsPhoneNumber(_key)) {
        _value = _value.replace("+", "%2B")
      }

    }
    newFilterState[filterStateKey].value = _value;
    setFilterState(newFilterState)
  }

  const handleSubmit = () => {
    //let searchBoxValue = searchBoxRef.current.value;
    let isFilled = isAllFilterFilled(filterState);

    if (!isFilled) {
      enqueueSnackbar(FILTER_ERROR_MESSAGE, { autoHideDuration: 3000, variant: 'error' })
    } else {
      props.onSearchTriggered(filterState);
      // setSingleValueFilter(filter.singleFieldSearch, '');
      // clearSingleFieldSearchBox()
    }
  }

  const filterValueChange = (_filter, _value) => {
    let _filterStateKey = getFilterStateKeyByFilterType(_filter)
    let object = filterState[_filterStateKey];

    if (_filter === filter.dateFrom || _filter === filter.dateTo) {
      setDate(_filter, _value);
    }
    if (Array.isArray(object.value)) {
      setListItems(_filter, _value)
    }
    if (isString(_value)) {
      setSingleValueFilter(_filter, _value)
    }
  }

  const selectFilters = (_filterType, _value) => {
    let newFillerState = { ...filterState };
    let _fillerStateKey = getFilterStateKeyByFilterType(_filterType)
    if (_filterType === filter[_filterType]) {
      newFillerState[_fillerStateKey] = {
        ...filterState[_fillerStateKey],
        show: _value
      }
    }

    if (_value) {
      let _newArr = [...filterSequence, _fillerStateKey]
      setFilterSequence(_newArr)
      
    } else {
      let _newArr = filterSequence.filter(value => value !== _fillerStateKey)
      setFilterSequence(_newArr)
      
    }

    if (newFillerState) {
      setFilterState(newFillerState)
      if (!isAnyFilterSelected(newFillerState)) {
        props.onSearchTriggered(newFillerState)
      }
    }
  }

  const createCheckedList = (itemList, filterStateItemList) => {
    let menuItems = [];
    if (itemList) {
      for (var i = 0; i < itemList.length; i++) {
        let isChecked = false;
        if (filterStateItemList) {
          for (var j = 0; j < filterStateItemList.length; j++) {
            if (itemList[i].name === filterStateItemList[j].name) {
              isChecked = true;
            }
          }
        }
        menuItems.push(<MenuItem key={i} value={itemList[i]}>
          <Checkbox checked={isChecked} />
          <ListItemText primary={itemList[i].value} />
        </MenuItem>);
      }
    }
    return menuItems;
  }

  const openFilterDialog = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    filterValueChange(filter.singleFieldSearch, '');
    clearSingleFieldSearchBox()
  };

  const clearSingleFieldSearchBox = () => {
    searchBoxRef.current.value = ''
  }

  const getSingleSearchElement = () => {
    return (<>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            style={styles.searchTextField}
            label={selectedOption}
            id="searchBox"
            placeholder={`Enter ${selectedOption}`}
            inputRef={searchBoxRef}
            onChange={(event) => filterValueChange(filter.singleFieldSearch, event.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <Select
            style={styles.searchSelectField}
            value={selectedOption}
            id="searchDropdown"
            onChange={handleDropdownChange}
            displayEmpty
            renderValue={() => (selectedOption ? selectedOption : 'Carrier Order ID')}
          >
            <MenuItem value="Carrier Order ID"><FormControlLabel control={<Radio />} label="Carrier Order ID" /></MenuItem>
            <MenuItem value="CRM ID"><FormControlLabel control={<Radio />} label="CRM ID" /></MenuItem>
            <MenuItem value="Source Name"><FormControlLabel control={<Radio />} label="Source Name" /></MenuItem>
            <MenuItem value="Recipient Name"><FormControlLabel control={<Radio />} label="Recipient Name" /></MenuItem>
            <MenuItem value="Source Phone"><FormControlLabel control={<Radio />} label="Source Phone" /></MenuItem>
            <MenuItem value="Recipient Phone"><FormControlLabel control={<Radio />} label="Recipient Phone" /></MenuItem>
            <MenuItem value="Source Address"><FormControlLabel control={<Radio />} label="Source Address" /></MenuItem>
            <MenuItem value="Recipient Address"><FormControlLabel control={<Radio />} label="Recipient Address" /></MenuItem>
            <MenuItem value="Notes"><FormControlLabel control={<Radio />} label="Notes" /></MenuItem>
          </Select>
        </FormControl>
      </Grid></>);
  }

  const getOrderDateFilter = () => {
    return (<><Grid item xs={3}>
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField
            label="From Date"
            format="DD-MM-YYYY"
            onChange={(newValue) => { filterValueChange(filter.dateFrom, newValue) }}
          />
        </LocalizationProvider>
      </FormControl>
    </Grid>

      <Grid item xs={3}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              label="To Date"
              format="DD-MM-YYYY"
              onChange={(newValue) => { filterValueChange(filter.dateTo, newValue) }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid></>)
  }

  const getMultipleCheckSelect = (label, filterType, selectValue, menuItems) => {
    const lowerCaseLabel = label.toLowerCase();
    const hypenedLowerCase = lowerCaseLabel.replace(' ', '-');
    const hyphenedLowercaselabel = hypenedLowerCase + "-label";
    const id = hypenedLowerCase + "-select";

    return (<Grid item xs={3}>
      <FormControl fullWidth >
        <InputLabel id={hyphenedLowercaselabel}>{label}</InputLabel>
        <Select
          labelId={hyphenedLowercaselabel}
          id={id}
          multiple
          value={selectValue}
          label={label}
          onChange={(event) => { filterValueChange(filterType, event.target.value) }}
          renderValue={(selected) => selected.map((x) => x.value).join(', ')}
        >
          {menuItems}
        </Select>
      </FormControl>
    </Grid>)
  }

  const getSearchFilter = () => {

    let countryMenuItems = createCheckedList(countries, filterState.countryFilter.value);
    let orderStatusMenuItems = createCheckedList(orderStatus, filterState.orderStatusFilter.value);
    let carriersMenuItems = createCheckedList(carriers, filterState.carrierFilter.value);
    let productTypeMenuItems = createCheckedList(productType, filterState.productTypeFilter.value);
    let cancellationPartyMenuItems = createCheckedList(cancellationParty, filterState.cancellationPartyFilter.value);
    let deliveryTypeMenuItems = createCheckedList(deliveryTypes, filterState.deliveryTypeFilter.value);

    let searchFilterElementArr = [];
    filterSequence.forEach((value) => {
      switch (value) {
        case "singleFieldSearchFilter": {
          searchFilterElementArr.push(getSingleSearchElement())
          break;
        }
        case "orderDateFilter": {
          searchFilterElementArr.push(getOrderDateFilter())
          break;
        }
        case "countryFilter" : {
          searchFilterElementArr.push(getMultipleCheckSelect("Country", filter.country, filterState.countryFilter.value, countryMenuItems))
          break;
        }
        case "carrierFilter" : {
          searchFilterElementArr.push(getMultipleCheckSelect("Carrier", filter.carriers, filterState.carrierFilter.value, carriersMenuItems))
          break;
        }
        case "orderStatusFilter" : {
          searchFilterElementArr.push(getMultipleCheckSelect("Order Status", filter.orderStatus, filterState.orderStatusFilter.value, orderStatusMenuItems))
          break;
        }
        case "productTypeFilter" : {
          searchFilterElementArr.push(getMultipleCheckSelect("Product Type", filter.productType, filterState.productTypeFilter.value, productTypeMenuItems))
          break;
        }
        case "deliveryTypeFilter" : {
          searchFilterElementArr.push(getMultipleCheckSelect("Delivery Type", filter.deliveryType, filterState.deliveryTypeFilter.value, deliveryTypeMenuItems))
          break;
        }
        case "cancellationPartyFilter" : {
          searchFilterElementArr.push(getMultipleCheckSelect("Cancellation Party", filter.cancellationParty, filterState.cancellationPartyFilter.value, cancellationPartyMenuItems))
          break;
        }
        default : {
          console.log("unknown filter type")
        }
      }

    })
  
    return (searchFilterElementArr.length>0 ? searchFilterElementArr : <></>)
  }
  return (<div style={styles.parentComponent}>

    <Box
      sx={styles.addFilterBox}>

      <Tooltip title="Choose which filters to show up" arrow>
        <Button id="filter-btn" style={styles.addFilterButton} onClick={openFilterDialog} aria-controls={open ? 'filter-menu' : undefined} aria-haspopup={'true'} aria-expanded={open ? 'true' : undefined} startIcon={<FilterAltIcon />}>Add Filter</Button>
      </Tooltip>

      <Button onClick={handleSubmit} startIcon={<SearchIcon />}>Apply Filter</Button>
    </Box>

    <div>
      <Box
        sx={styles.menuItemBox}>
        <Menu
          id="filter-menu"
          aria-labelledby="filter-btn"
          anchorEl={anchorEl}

          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem ><div><Switch defaultChecked={filterState.carrierFilter.show} onChange={(_event, _checked) => { selectFilters(filter.carriers, _checked) }} /> Carriers</div></MenuItem>
          <MenuItem ><div><Switch defaultChecked={filterState.countryFilter.show} onChange={(_event, _checked) => { selectFilters(filter.country, _checked) }} /> Country</div></MenuItem>
          <MenuItem ><div><Switch defaultChecked={filterState.orderStatusFilter.show} onChange={(_event, _checked) => { selectFilters(filter.orderStatus, _checked) }} /> Order Status</div></MenuItem>
          <MenuItem ><div><Switch defaultChecked={filterState.orderDateFilter.show} onChange={(_event, _checked) => { selectFilters(filter.dateFrom, _checked) }} /> Date Range</div></MenuItem>
          <MenuItem ><div><Switch defaultChecked={filterState.productTypeFilter.show} onChange={(_event, _checked) => { selectFilters(filter.productType, _checked) }} /> Product Type</div></MenuItem>
          <MenuItem ><div><Switch defaultChecked={filterState.cancellationPartyFilter.show} onChange={(_event, _checked) => { selectFilters(filter.cancellationParty, _checked) }} /> Cancellation Party</div></MenuItem>
          <MenuItem ><div><Switch defaultChecked={filterState.deliveryTypeFilter.show} onChange={(_event, _checked) => { selectFilters(filter.deliveryType, _checked) }} /> Delivery Type</div></MenuItem>
          <MenuItem ><div><Switch defaultChecked={filterState.singleFieldSearchFilter.show} onChange={(_event, _checked) => { selectFilters(filter.singleFieldSearch, _checked) }} />Keyword Search</div></MenuItem>
        </Menu>
      </Box>
    </div>

    <Box
      sx={styles.filterStateBox}>

      <Grid container spacing={2} >

       {getSearchFilter()}
      </Grid>
    </Box>
  </div >);
}

export default DashboardFilter;