import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, useLocation, useNavigate,Route} from "react-router-dom";
import { LOGIN_STATE, STATUS_CODE_ACCESS_TOKEN_REFRESH_SUCCESS } from "../utils/Constants";
import { getNewAuthTokens, setAppStateOnLoggedIn, setAppStateOnLoggedOut } from "../services/AuthService";

import { useAppLogout } from "../hooks/useAppLogout";
import { ROUTES } from "./Routes";
import { getRouteOnPop } from "../utils/Utils";
export const RouteHandler = () => {
    const query = useLocation().pathname;
    const user = useSelector((state) => state.user);
    let { setShouldLogout} = useAppLogout();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const checkIfUserTokenIsValid = async () => {
        if (
            user &&
            user.accessToken !== null &&
            user.refreshToken !== null &&
            user.accessToken.length > 0 &&
            user.refreshToken.length > 0
        ) {
            let appResponse = await getNewAuthTokens(user);
            if (appResponse.statusCode === STATUS_CODE_ACCESS_TOKEN_REFRESH_SUCCESS) {
                setAppStateOnLoggedIn(appResponse, dispatch, user);
                redirectOnLoggedIn(user, query);
                return true;
            } else {
                setAppStateOnLoggedOut(dispatch);
                setShouldLogout(true)
                return false;
            }
        } else {
            setAppStateOnLoggedOut(dispatch);
            setShouldLogout(true)
            return false;
        }
    };  

    const redirectOnLoggedIn = (user, query) => {
        if(user.loginState===LOGIN_STATE.yes){
            if (query === '/') {
                navigate('/dashboard', { replace: true });
            }
        }
    }

    useEffect(() => {
        if(user.loginState === LOGIN_STATE.maybe){
            checkIfUserTokenIsValid();
        }
        if(user.loginState === LOGIN_STATE.yes){
            let route = getRouteOnPop(window.location.href)
                redirectOnLoggedIn(user, route)
            window.onpopstate = ()=>{
                let route = getRouteOnPop(window.location.href)
                redirectOnLoggedIn(user, route)
            }
        }
        return (()=>{
            
        })
    }, [user.loginState]);

    return (<>
        <Routes  >
            {ROUTES.map(route => (
                <Route path={route.path} element={route.component} key={route.path} />
            )
            )}
        </Routes>
 
    </>)
}
