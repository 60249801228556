import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import logo from '../assets/images/logo.png';
import React from "react";
import {  useDispatch, useSelector } from "react-redux";
import { useAppLogout } from "../hooks/useAppLogout";
import * as CONSTANTS from "../utils/Constants";
import { Size, defaultTheme } from "../styles/Theme";

const styles = {
    topNavStyle: {
        backgroundColor:defaultTheme.palette.primary.contrastText,
        height : Size.sixtyFive
    },
    buttonStyle: {
        color: defaultTheme.palette.primary.main, 
        borderColor: defaultTheme.palette.primary.main
    }
}

export const TopNav = () => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    let { setShouldLogout} = useAppLogout();
    const handleChange = async (event) => {
        setShouldLogout(true)
    }


  return (<>
    <AppBar position="static" style={styles.topNavStyle}>
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <img alt="logo" width={130} height={50} src={logo} onClick={()=>{
                }}></img>
                <Typography component="div" sx={{ flexGrow: 1 }}/>
                  {user.loginState === CONSTANTS.LOGIN_STATE.yes? <Button variant="outlined" onClick={handleChange} sx={styles.buttonStyle}>Logout</Button> : null}
            </Toolbar>
        </Container>
    </AppBar>
</>)}
