import { Report } from "./components/Reports";
import { ProtectedRoutes } from "../../common/ProtectedRoute";
import React from "react";
import { useDbData } from "./hooks/useDbData";
import { Table } from "./components/Table";
import MenuDrawer from "./components/MenuDrawer";
import CommandCenter from "../CommandCenter";
const Dashboard = (props) => {
  const { avgDeliveryTimeReport,
    allTimeDeliveriesStatusesReport,
    allTimeDeliveredByPartner,
    ordersByMonthCountry,
    ordersByMonthPartner,
    distinctSearchFilters,
    averageOrderCosts
  }
    = useDbData(props)


  const [view, setView] = React.useState('Dashboard');
  const [drawerState, setDrawerState] = React.useState('closed');

  const onViewSelected = (view) => {
    setView(view)
  }

  const onDrawerOpen = ()=>{
    setDrawerState('open')
  }

  const onDrawerClose = ()=>{
    setDrawerState('closed')
  }
  return (
    <>
      <ProtectedRoutes>
        <MenuDrawer onDrawerOpen={onDrawerOpen}  onDrawerClose={onDrawerClose} onViewSelected={onViewSelected} >
          <>
            {view === 'Dashboard' ? <Table
            distinctSearchFilters={distinctSearchFilters}
            /> : <></>}
            {view === 'Reports' ? <Report
              avgDeliveryTimeReport={avgDeliveryTimeReport}
              allTimeDeliveriesStatusesReport={allTimeDeliveriesStatusesReport}
              allTimeDeliveredByPartner={allTimeDeliveredByPartner}
              ordersByMonthCountry = {ordersByMonthCountry}
              ordersByMonthPartner={ordersByMonthPartner}
              averageOrderCosts={averageOrderCosts}
            /> : <></>}
             {view === 'Command Center' ? <CommandCenter drawerState={drawerState}/> : <></>}
          </>

        </MenuDrawer>

      </ProtectedRoutes>
      </>
  );
};

export default Dashboard;


