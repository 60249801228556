import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListIcon from '@mui/icons-material/List';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { useSelector } from 'react-redux';
import { setCommandCenterRole } from "../../../store/reducers/role"

const listItemTextStyle = (open) => {
    return { opacity: open ? 1 : 0 };
}

const drawerWidth = 240;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const style = {
    parentContainer: {
        display: 'flex'
    },
    childContainer : {
        width:'100%', margin: 1
    }
}



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MenuDrawer(props) {
    const [open, setOpen] = React.useState(false);

    const commandCenterRole = useSelector(setCommandCenterRole);

    const handleDrawerOpen = () => {
        setOpen(true);
        props.onDrawerOpen();
    };

    const handleDrawerClose = () => {
        setOpen(false);
        props.onDrawerClose();
    };

    return (
        <Box sx={style.parentContainer}>

            <Drawer variant="permanent" open={open}>
                <List>
                    <ListItemButton
                        onMouseEnter={handleDrawerOpen}
                        onMouseLeave={handleDrawerClose}
                        onClick={() => { props.onViewSelected('Dashboard') }}
                    >
                        <ListItemIcon
                        >
                            <ListIcon onMouseEnter={handleDrawerOpen}
                                onMouseLeave={handleDrawerClose} />
                        </ListItemIcon>
                        <ListItemText primary={'Dashboard'} sx={listItemTextStyle(open)} />
                    </ListItemButton>

                    <ListItemButton
                        onMouseEnter={handleDrawerOpen}
                        onMouseLeave={handleDrawerClose}
                        onClick={() => { props.onViewSelected('Reports') }}
                    >
                        <ListItemIcon
                        >
                            <AssessmentIcon />
                        </ListItemIcon>

                        <ListItemText primary={'Reports'} sx={listItemTextStyle(open)} />
                    </ListItemButton>

                    {commandCenterRole && (
                        <ListItemButton
                            onMouseEnter={handleDrawerOpen}
                            onMouseLeave={handleDrawerClose}
                            onClick={() => { props.onViewSelected('Command Center') }}
                        >
                            <ListItemIcon
                            >
                                <AddLocationAltIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Command Center'} sx={listItemTextStyle} />
                        </ListItemButton>
                    )}
                </List>
                <Divider />
            </Drawer>

            <Box sx={style.childContainer}>
                {props.children}
            </Box>
        </Box>
    );
}