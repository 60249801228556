import Container from "@mui/material/Container";
import { TopNav } from "./TopNav";
import { RouteHandler } from "../setup/RouteHandler";
import { BottomNav } from "./BottomNav";
import { ThemeProvider } from "@emotion/react";
import { defaultTheme } from "../styles/Theme";
import { Box } from "@mui/system";


function Root() {
    return (
        <Box >
            <ThemeProvider theme={defaultTheme}>
                <Box style={{ backgroundColor: defaultTheme.palette.common.pageBg} }>
                    <TopNav />
                    <RouteHandler />
                    <BottomNav />
                </Box>
            </ThemeProvider>
        </Box>
    );
}

export default Root;
