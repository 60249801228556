import Dashboard from "../pages/Dashboard";
import ForgotPassword  from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Signin  from "../pages/Signin";
import Track from "../pages/Track";
import CommandCenter from "../pages/CommandCenter";

export const ROUTES = [{
    path: '/dashboard',
    protected: true,
    component: (<Dashboard />)
},
{
    path: '/track',
    protected: false,
    component: (<Track/>)
},
{
    path: '/',
    protected: false,
    component: (<Signin />)
},
{
    path: '/forgotPassword',
    protected: false,
    component: (<ForgotPassword />)
},
{
    path: 'resetPassword/:token',
    protected: false,
    component: (<ResetPassword />)
},
{
    path: '/command-center',
    protected: true,
    component: (<CommandCenter />)
}
]
