import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist'
import logger from 'redux-logger';
import UserReducer from './reducers/user';
import storage from 'redux-persist/lib/storage';
import RoleReducer from "./reducers/role";

let rootReducer = combineReducers({
    user : UserReducer,
    role : RoleReducer
});

const persistConfig = {
    key: 'logistics',
    storage,
  }

  const makeConfiguredStore = (rootReducer) =>
  configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      (process.env.NODE_ENV ==='development' && typeof window !== "undefined")
        ? getDefaultMiddleware({ serializableCheck: false }).concat(logger)
        : getDefaultMiddleware({ serializableCheck: false }),
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  export const store = makeConfiguredStore(persistedReducer);
  store.__persistor = persistStore(store); // Nasty hack   
  
