import "./App.css";
import { SnackbarProvider } from "notistack";
import Root from "./common/Root";



function App (props) {  
  return (
    <div>    
      <SnackbarProvider autoHideDuration={1000}>
        <Root></Root>
        </SnackbarProvider>
    </div>
  );
}

export default App;
