import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthHeaders } from "../../../services/AuthService";
import { get } from "../../../utils/ApiUtils";
import * as CONSTANTS from "../../../utils/Constants";

export const useDbData = (props) => {
    const _user = useSelector((state) => state.user)
    const [avgDeliveryTimeReport, setAvgDeliveryTimeReport] = useState([]);
    const [logistics, setLogistics] = useState([]);
    const dispatch = useDispatch()
    const [allTimeDeliveriesStatusesReport, setAllTimeDeliveriesStatusesReport] = useState({});
    const [allTimeDeliveredByPartner, setAllTimeDeliveredByPartner] = useState([]);
    const [
        ordersByMonthCountry,
        setOrdersByMonthCountry,
    ] = useState(undefined);
    const [
        ordersByMonthPartner,
        setOrdersByMonthPartner,
    ] = useState(undefined);
    const [distinctSearchFilters, setDistinctSearchFilters] = useState([]);
    const [
        averageOrderCosts,
        setAverageOrderCosts,
    ] = useState(undefined);

    useEffect(() => {
        const getAllTimeAvgDeliveryTimeReport = async () => {
            let avgDeliveryTimeReport = {};

            let accessToken = _user.accessToken;
            let authHeaders = getAuthHeaders(accessToken);
            const avgDeliveryTimeReportResponse = await get(
                process.env.REACT_APP_SERVER_URL +
                CONSTANTS.LS_AVG_DELIEVERY_TIME_STATS_PATH,
                {
                    "Content-Type": "application/json",
                    ...authHeaders
                }, dispatch, _user
            );

            if (avgDeliveryTimeReportResponse.code === 2000) {
                avgDeliveryTimeReport =
                    avgDeliveryTimeReportResponse.result;

                setAvgDeliveryTimeReport(avgDeliveryTimeReport);
            } else {
                setAvgDeliveryTimeReport([{}]);
            }
        };

        
        const getAllTimeCompletedDeliveriesByPartnersReport = async () => {
            let allTimeDeliveredByPartner = [];
            let accessToken = _user.accessToken;
            let authHeaders = getAuthHeaders(accessToken);
            const allTimeDeliveriesStatusesReportResponse = await get(
                process.env.REACT_APP_SERVER_URL +
                CONSTANTS.LS_COMPLETED_DELIVERIES_STATS_PATH,
                { "Content-Type": "application/json", ...authHeaders },
                dispatch, _user
            );

            if (allTimeDeliveriesStatusesReportResponse.code === 2000) {
                allTimeDeliveredByPartner =
                    allTimeDeliveriesStatusesReportResponse.result;
                setAllTimeDeliveredByPartner(allTimeDeliveredByPartner);
            } else {
                setAllTimeDeliveredByPartner([]);
            }
        };

        const getLogistics = async () => {
            let logistics = [];
            let accessToken = _user.accessToken;
            let authHeaders = getAuthHeaders(accessToken);
            const logisticsResponse = await get(
                process.env.REACT_APP_SERVER_URL + CONSTANTS.LS_FIND_PATH,
                { "Content-Type": "application/json", ...authHeaders },
                dispatch, _user
            );
    
            if (logisticsResponse.code === 2000) {
                logistics = logisticsResponse.result;
                setLogistics(logistics);
            } else {
                setLogistics([]);
            }
        };

        const getOrdersByMonthCountryReport = async () => {
            
            let ordersByMonthCountry = [];
            let accessToken = _user.accessToken;
            let authHeaders = getAuthHeaders(accessToken);
            const ordersByMonthCountryResponse = await get(
                process.env.REACT_APP_SERVER_URL +
                CONSTANTS.LS_ORDERS_BY_MONTH_STATS_PATH + "?column=country",
                { "Content-Type": "application/json", ...authHeaders },
                dispatch, _user
            );
    
            if (ordersByMonthCountryResponse.code === 2000) {
                ordersByMonthCountry = ordersByMonthCountryResponse.result;
                
                setOrdersByMonthCountry(ordersByMonthCountry);
            } else {
                setOrdersByMonthCountry([]);
            }
        };

        const getOrdersByMonthPartnerReport = async () => {
            
            let ordersByMonthPartner = [];
            let accessToken = _user.accessToken;
            let authHeaders = getAuthHeaders(accessToken);
            const ordersByMonthPartnerResponse = await get(
                process.env.REACT_APP_SERVER_URL +
                CONSTANTS.LS_ORDERS_BY_MONTH_STATS_PATH + "?column=deliveryPartner",
                { "Content-Type": "application/json", ...authHeaders },
                dispatch, _user
            );
    
            if (ordersByMonthPartnerResponse.code === 2000) {
                ordersByMonthPartner = ordersByMonthPartnerResponse.result;
                
                setOrdersByMonthPartner(ordersByMonthPartner);
            } else {
                setOrdersByMonthPartner([]);
            }
        };

        const getAverageOrderCostsReport = async () => {
            
            let averageOrderCosts = [];
            let accessToken = _user.accessToken;
            let authHeaders = getAuthHeaders(accessToken);
            const averageOrderCostsResponse = await get(
                process.env.REACT_APP_SERVER_URL +
                CONSTANTS.LS_AVERAGE_ORDER_COSTS_STATS_PATH,
                { "Content-Type": "application/json", ...authHeaders },
                dispatch, _user
            );
    
            if (averageOrderCostsResponse.code === 2000) {
                averageOrderCosts = averageOrderCostsResponse.result;
                setAverageOrderCosts(averageOrderCosts);
            } else {
                setAverageOrderCosts([]);
            }
        };

        const getDistinctSearchFilters = async () => {
            
            let distinctSearchFilters = {};
            let accessToken = _user.accessToken;
            let authHeaders = getAuthHeaders(accessToken);
            const distinctSearchFiltersResponse = await get(
                process.env.REACT_APP_SERVER_URL +
                CONSTANTS.LS_DISTINCT_SEARCH_FILTERS_PATH,
                { "Content-Type": "application/json", ...authHeaders },
                dispatch, _user
            );
    
            if (distinctSearchFiltersResponse.code === 2000) {
                distinctSearchFilters = distinctSearchFiltersResponse.result;
                
                setDistinctSearchFilters(distinctSearchFilters);
                return distinctSearchFilters
            } else {
                setDistinctSearchFilters({});
            }
        };

        const init = async () => {
            if (_user.loginState === CONSTANTS.LOGIN_STATE.yes) {
                    getLogistics();
                    getAllTimeAvgDeliveryTimeReport();
                    getAllTimeDeliveriesStatusesReport();
                    getAllTimeCompletedDeliveriesByPartnersReport();
                    getOrdersByMonthCountryReport();
                    getOrdersByMonthPartnerReport();
                    getDistinctSearchFilters();
                    getAverageOrderCostsReport();
            }
        }
        init();
    }, [_user.loginState])

    const getAllTimeDeliveriesStatusesReport = async (deliveryPartnerName) => {
        let allTimeDeliveriesStatusesReport = {};

        let accessToken = _user.accessToken;
        let authHeaders = getAuthHeaders(accessToken);
        let url = (deliveryPartnerName !== undefined)
        ? process.env.REACT_APP_SERVER_URL +
        CONSTANTS.LS_DELIVERY_STATUES_STATS_PATH + "?deliveryPartner=" + deliveryPartnerName.target.value 
        : process.env.REACT_APP_SERVER_URL +
        CONSTANTS.LS_DELIVERY_STATUES_STATS_PATH
        
        const allTimeDeliveriesStatusesReportResponse = await get(
            url,
            { "Content-Type": "application/json", ...authHeaders },
            dispatch, _user
        );

        if (allTimeDeliveriesStatusesReportResponse.code === 2000) {
            
            allTimeDeliveriesStatusesReport =
                allTimeDeliveriesStatusesReportResponse.result;

            setAllTimeDeliveriesStatusesReport(allTimeDeliveriesStatusesReport);
            return allTimeDeliveriesStatusesReport
        } else {
            setAllTimeDeliveriesStatusesReport({});
            return {}
        }
    };

    return {
        logistics: logistics,
        avgDeliveryTimeReport: avgDeliveryTimeReport,
        allTimeDeliveriesStatusesReport: allTimeDeliveriesStatusesReport,
        allTimeDeliveredByPartner: allTimeDeliveredByPartner,
        getAllTimeDeliveriesStatusesReport,
        ordersByMonthCountry: ordersByMonthCountry,
        ordersByMonthPartner: ordersByMonthPartner,
        distinctSearchFilters: distinctSearchFilters,
        averageOrderCosts: averageOrderCosts
    }
}