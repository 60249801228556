import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import { useCommandCenterService } from "../../services/CommandCenterService"
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useInterval } from "usehooks-ts";
import { HERE_MAPS_KILLI } from "../../utils/Constants";
import Fab from '@mui/material/Fab';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const styles = {
    map: {
        width: '100%',
        height: 'auto',
        bgcolor: 'yellow'
    },
    bubble: {
        fontSize: '11px',
        lineHeight: '15px',
        color: 'navy',
        width: '200px'
    },
    containerStyle: {
        height: '82vh',
        width: '93vw',
        padding: 0,
        margin: 0
    }
}

const CommandCenter = (props) => {
    const { getMapMarkers, scheduleUpdateCoordinates } = useCommandCenterService();
    
    let H = window.H;
    let clusteredDataProvider = useRef(null);
    let dataPointsMap  = useRef({});
    let arcMap  = useRef({});
    
    const [windowDimens, setWindowDimens] = useState({
        height: 0,
        width: 0
    });

    const [open, setOpen] = useState(false);
    const [isUserActive, setIsUserActive] = useState(true);
    //const [arcMap, setArcMap] = useState({});


    //gets called when user clicks on modal button or anywhere, mark user as active
    const handleClose = () => {
        setIsUserActive(true);
        setOpen(false);
    };

    const initMap = async () => {
        const onMarkerClick = (e) => {

            let position = e.target.getGeometry();
            let data = e.target.getData();
            let bubbleContent = data.content;
            let bubble = onMarkerClick.bubble;

            if (!bubble) {

                bubble = new H.ui.InfoBubble(position, {
                    content: bubbleContent
                });
                bubble.close()
                ui.addBubble(bubble);

                onMarkerClick.bubble = bubble;
            } else {

                map.setCenter(
                    map.screenToGeo(
                        e.currentPointer.viewportX,
                        e.currentPointer.viewportY
                    ),
                    true
                );

                const zoomIn = () => {
                    map.setCenter(position);
                    map.setZoom(map.getZoom() + 3, true);
                    map.removeEventListener('mapviewchangeend', zoomIn);
                };

                if (e.target.getData().content !== undefined) {
                    bubble.setPosition(position);
                    bubble.setContent(bubbleContent);
                    bubble.open();
                }
                else {
                    map.addEventListener('mapviewchangeend', zoomIn);
                }
            }

        }

        //await scheduleUpdateCoordinates();

        

        let dataPoints = [];

        const { driverDatapoints, requiredArcs, arcMarkersMap} = await getMapMarkers(false);
        
        arcMap.current = arcMarkersMap;

        driverDatapoints.forEach(element => {
            let dataPoint = new H.clustering.DataPoint(element.position.lat, element.position.lng, null, element);

            dataPoints.push(dataPoint);
            dataPointsMap.current[element.id] = dataPoint;
        });

        for (const [key, value] of Object.entries(requiredArcs)) {

            let arcInfo2 = JSON.parse(JSON.stringify(arcMarkersMap[key]));

            arcInfo2.content += `<div><p>Incoming orders: ${value.incoming}</p><p>Outgoing orders: ${value.outgoing}</p></div>`;
            arcInfo2.incoming = value.incoming;
            arcInfo2.outgoing = value.outgoing;

            let dataPoint = new H.clustering.DataPoint(arcInfo2.position.lat, arcInfo2.position.lng, null, arcInfo2);
            
            dataPoints.push(dataPoint);
            dataPointsMap.current[key] = dataPoint;
        }
        
        // Create a clustering provider with a custom theme
        clusteredDataProvider.current = new H.clustering.Provider(dataPoints, {
            clusteringOptions: {
                eps: 20,
                minWeight: 2
            }
        });

        let defaultTheme = clusteredDataProvider.current.getTheme();

        let customTheme = {
            getClusterPresentation: function (cluster) {

                let clusterMarker = defaultTheme.getClusterPresentation.call(defaultTheme, cluster);

                return clusterMarker;
            },
            getNoisePresentation: function (noisePoint) {

                let data = noisePoint.getData(),

                    noiseMarker = new H.map.Marker(noisePoint.getPosition(), {

                        min: noisePoint.getMinZoom(),
                        icon: new H.map.Icon(data.icon, {

                            size: {
                                w: 26,
                                h: 26
                            }

                        })
                    });

                noiseMarker.setData(data);

                return noiseMarker;
            }
        }

        clusteredDataProvider.current.setTheme(customTheme);

        clusteredDataProvider.current.addEventListener('tap', onMarkerClick);

        let layer = new H.map.layer.ObjectLayer(clusteredDataProvider.current);

        let button = document.getElementById('reset');
        button.addEventListener('click', async () => {

            let dataPoints = clusteredDataProvider.current.c.a;

            let minLat = dataPoints[0].lat, maxLat = dataPoints[0].lat, minLng = dataPoints[0].lng, maxLng = dataPoints[0].lng;

            for (let i = 1; i < dataPoints.length; i++) {
                let lat = dataPoints[i].lat, lng = dataPoints[i].lng;
                if (lat < minLat) minLat = lat - 2;
                if (lat > maxLat) maxLat = lat + 2;
                if (lng < minLng) minLng = lng - 2;
                if (lng > maxLng) maxLng = lng + 2;
            }

            console.log(`minLat = ${minLat} | minLng = ${minLng}, maxLat = ${maxLat}, maxLng = ${maxLng}`);
            // Set the view to the bounding box
            let boundingBox = new H.geo.Rect(minLat, minLng, maxLat, maxLng);

            map.getViewModel().setLookAtData({
                bounds: boundingBox
            });  
        })

        let minLat = dataPoints[0].lat, maxLat = dataPoints[0].lat, minLng = dataPoints[0].lng, maxLng = dataPoints[0].lng;

        for (let i = 1; i < dataPoints.length; i++) {
            let lat = dataPoints[i].lat, lng = dataPoints[i].lng;
            if (lat < minLat) minLat = lat - 2;
            if (lat > maxLat) maxLat = lat + 2;
            if (lng < minLng) minLng = lng - 2;
            if (lng > maxLng) maxLng = lng + 2;
        }

        console.log(`minLat = ${minLat} | minLng = ${minLng}, maxLat = ${maxLat}, maxLng = ${maxLng}`);
        // Set the view to the bounding box
        let boundingBox = new H.geo.Rect(minLat, minLng, maxLat, maxLng);

        let platform = new H.service.Platform({
            apikey: HERE_MAPS_KILLI
        });

        var engineType = H.Map.EngineType['HARP'];
        var style = new H.map.render.harp.Style('https://heremaps.github.io/maps-api-for-javascript-examples/change-harp-style-at-load/data/night.json');
        var vectorLayer = platform.getOMVService().createLayer(style, { engineType });

        let defaultLayers = platform.createDefaultLayers();
        defaultLayers.vector.normal.map.setMax(16);

        let map = new H.Map(document.getElementById('map'), vectorLayer, {
            engineType,
            zoom: 6,
            pixelRatio: window.devicePixelRatio || 1
        });

        map.addLayer(vectorLayer);
        //window.addEventListener('resize', () => map.getViewPort().resize());

        let behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

        let ui = new H.ui.UI(map);

        map.addLayer(layer);

        map.getViewModel().setLookAtData({
            bounds: boundingBox
        });
    }

    //opens a modal every 10 seconds, if user click button or closes modal, he is considered active
    let checkIfUserIsActive = () => {
        setIsUserActive(false);
        setOpen(true);
    }

    let getUpdatedMarkers = async () => {
        
        if(isUserActive){

            const { driverDatapoints, requiredArcs } = await getMapMarkers(true);
            
            let updatedDriversAndArcsMap = {};

            driverDatapoints.forEach(element => {
                let dataPoint = new H.clustering.DataPoint(element.position.lat, element.position.lng, null, element);
    
                updatedDriversAndArcsMap[element.id] = dataPoint;
            });

            for (const [key, value] of Object.entries(requiredArcs)) {

                //Somehow arcMap is getting updated, with incoming and outgoing which should not happen
                let arcInfo = JSON.parse(JSON.stringify(arcMap.current[key]));

                arcInfo.content += `<div><p>Incoming orders: ${value.incoming}</p><p>Outgoing orders: ${value.outgoing}</p></div>`;
                arcInfo.incoming = value.incoming;
                arcInfo.outgoing = value.outgoing;

                let dataPoint = new H.clustering.DataPoint(arcInfo.position.lat, arcInfo.position.lng, null, arcInfo);
                
                updatedDriversAndArcsMap[key] = dataPoint;
            }
    

            for (const [key, value] of Object.entries(dataPointsMap.current)) {
                
                if(!updatedDriversAndArcsMap.hasOwnProperty(key)){
                    console.log("removeDataPoint => ", value);

                    clusteredDataProvider.current.removeDataPoint(value);

                    delete dataPointsMap[key];
                }
                else if(updatedDriversAndArcsMap.hasOwnProperty(key) && value.data.type === "ARC"){

                    if((value.data.incoming !== updatedDriversAndArcsMap[key].data.incoming) 
                        || (value.data.outgoing !== updatedDriversAndArcsMap[key].data.outgoing) 
                    ){
                        clusteredDataProvider.current.removeDataPoint(value);

                        delete dataPointsMap[key];    
                    }
                }
            }

            for (const [key, value] of Object.entries(updatedDriversAndArcsMap)) {
                
                if(!dataPointsMap.current.hasOwnProperty(key)){
                    console.log("addDataPoint => ", value);

                    clusteredDataProvider.current.addDataPoint(value);

                    dataPointsMap.current[key] = value;
                }
            }

        }
    }

    let updateCoordinates = async () => {
        
        if(isUserActive){
            console.log("updateCoordinates | isUserActive => ", isUserActive);
            scheduleUpdateCoordinates();
        }
    }
    
    useEffect(() => {
        initMap();
    }, []);
    
    useInterval(
        ()=>{
            getUpdatedMarkers()
        },25000
    )

    useInterval(
        ()=>{
            checkIfUserIsActive()
        },300000
    )
    
    useInterval(
        ()=>{
            //updateCoordinates()
        },70000
    )

    let containerHeight = styles.containerStyle.height;
    let containerWidth = styles.containerStyle.width;

    return (

        <Container onResize={(e) => { console.log(e) }} component="main" maxWidth="xl" style={{
            ...styles.containerStyle, height: containerHeight,
            width: containerWidth
        }}>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'white',
                    border: '1px solid',
                    boxShadow: 24,
                    p: 4,
                }}>
                
                    <Typography>
                        Are you still monitoring?
                    </Typography>

                    <Button onClick={handleClose} style={{'marginLeft': '30%', border: '1px solid', 'marginTop': '5%'}}>Yes</Button>
                
                </Box>
            </Modal>
            <Fab sx={{position: "absolute",
                top: 90,
                left: 90,
                color: "navy"
            }}>
                <RestartAltIcon id="reset"/>
            </Fab>
            <div id="map" style={{ height: containerHeight, width: containerWidth }}></div>
        </Container>

    )
}

export default CommandCenter