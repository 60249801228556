import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { defaultTheme } from "../styles/Theme";

export const BottomNav = () => {
  return (<>
    <AppBar position="static" style={{backgroundColor:defaultTheme.palette.primary.main}}>
        <Container maxWidth="xl">
            <Toolbar disableGutters>
            </Toolbar>
        </Container>
    </AppBar>
</>)}
