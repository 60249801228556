import { del, get, post, put } from "../utils/ApiUtils";
import {
  LOGIN_STATE,
  STATUS_CODE_ACCESS_TOKEN_REFRESH_SUCCESS,
  STATUS_CODE_AUTH_REFRESH_TOKEN_EXPIRED,
  STATUS_CODE_NO_SIDE_EFFECTS,
} from "../utils/Constants";
import { USER_UNAUTHROIZED_MESSAGE } from "../utils/StringConstants";
import  {AppResponseDto } from "../dtos/AppResponseDto";
import {
  STATUS_CODE_SUCCESS,
  STATUS_CODE_AUTH_LOGIN_ERROR,
  KEYCLOAK_TOKEN_LOGIN_PATH,
  KEYCLOAK_TOKEN_REFRESH_PATH,
  KEYCLOAK_TOKEN_FORGOT_PASSWORD_PATH,
  STATUS_CODE_AUTH_FORGOT_PASSWORD_ERROR,
  KEYCLOAK_TOKEN_RESET_PASSWORD_PATH,
  STATUS_CODE_AUTH_RESET_PASSWORD_ERROR
} from "../utils/Constants";

export const login = async function (username, password) {
  let response = await post(
    process.env.REACT_APP_SERVER_URL + KEYCLOAK_TOKEN_LOGIN_PATH,
    {
      "Content-Type": "application/json"
    },
    {
      username: username,
      password: password,
    }
  );
  if (response.result._status.message === "success") {
    let appResponse = new AppResponseDto(
      STATUS_CODE_SUCCESS,
      response.result._status.message,
      response.result
    );
    return appResponse;
  } else {
    let appResponse = new AppResponseDto(
      STATUS_CODE_AUTH_LOGIN_ERROR,
      response.result._status.message,
      response.result
    );
    return appResponse;
  }
};

export const getAuthHeaders = (accessToken) => {
  return {
    "Bolt-Tenant-Id": process.env.REACT_APP_BOLT_TENANT_ID,
    "Bolt-Country-Code": process.env.REACT_APP_BOLT_COUNTRY_CODE,
    Authorization: `Bearer ${accessToken}`,
  };
};

export const getNewAuthTokens = async (user) => {
  let response = await tokenRefresh(user);
  if (response._status && response._status.code === 400) {
    let appResponseDto = new AppResponseDto(
      STATUS_CODE_AUTH_REFRESH_TOKEN_EXPIRED,
      "User needs to logged out",
      response
    );
    console.error("User session expired. Logging out")
    return appResponseDto;
  } else if (response._status && response._status.message === "success") {
    let appResponseDto = new AppResponseDto(
      STATUS_CODE_ACCESS_TOKEN_REFRESH_SUCCESS,
      "Access Token is refreshed",
      response
    );
    return appResponseDto;
  } else {
    let appResponseDto = new AppResponseDto(
      STATUS_CODE_NO_SIDE_EFFECTS,
      "no side effects",
      response
    );
    return appResponseDto;
  }
};

export const setAppStateOnLoggedIn = (appResponse, dispatch, user) => {
  dispatch({
    type: "user/setUser",
    payload: {
      email: user.email,
      refreshToken: appResponse.data.refresh_token,
      accessToken: appResponse.data.access_token,
      accessTokenExpiry: appResponse.data.expires_in,
      refreshTokenExpiry: appResponse.data.refresh_expires_in,
    },
  });
  dispatch({
    type: "user/setUserLoginState",
    payload: { loginState: LOGIN_STATE.yes },
  });
}

export const setAppStateOnLoggedOut = (dispatch) => {
  dispatch({
    type: "user/setUser",
    payload: {
      email: "",
      refreshToken: "",
      accessToken: "",
    },
  });
  dispatch({
    type: "user/setUserLoginState",
    payload: { loginState: LOGIN_STATE.no },
  });
}

export const isUserAuthorized = (data) => {
  let val = ((data.message === USER_UNAUTHROIZED_MESSAGE) || (data.Message === USER_UNAUTHROIZED_MESSAGE)) ? false : true;
  return val;
}

export const tokenRefresh = async (user) => {
  let response = await post(
    process.env.REACT_APP_SERVER_URL + KEYCLOAK_TOKEN_REFRESH_PATH,
    {
      "Content-Type": "application/json"
    },
    {
      refreshToken: user.refreshToken,
    }
  );
  return response.result;
};

export const forgotPassword = async function (username) {
  let response = await post(
    process.env.REACT_APP_SERVER_URL + KEYCLOAK_TOKEN_FORGOT_PASSWORD_PATH,
    {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": '*'
    },
    {
      username: username
    }
  );
  if (response.code === 2000) {
    let appResponse = new AppResponseDto(
      STATUS_CODE_SUCCESS,
      response.message,
      response.result
    );
    return appResponse;
  } else {
    let appResponse = new AppResponseDto(
      STATUS_CODE_AUTH_FORGOT_PASSWORD_ERROR,
      response.message,
      response.result
    );
    return appResponse;
  }
};

export const resetPassword = async function (token, password, confirmPassword) {
  let response = await put(
    process.env.REACT_APP_SERVER_URL + KEYCLOAK_TOKEN_RESET_PASSWORD_PATH,
    {
      "Content-Type": "application/json"
    },
    {
      token: token.toString(),
      password: password,
      confirmPassword: confirmPassword
    }
  );
  
  if (response.code === 2000) {
    let appResponse = new AppResponseDto(
      STATUS_CODE_SUCCESS,
      response.message,
      response.result
    );
    return appResponse;
  } else {
    let appResponse = new AppResponseDto(
      STATUS_CODE_AUTH_RESET_PASSWORD_ERROR,
      response.message,
      response.result
    );
    return appResponse;
  }
};

const retryApiWithNewTokens = async (url, method='GET'|'POST'|'PUT'|'DELETE', headers, body, dispatch, user)=>{
  let authTokenResponse = await getNewAuthTokens(user);
  let data;
  if (authTokenResponse.statusCode === STATUS_CODE_ACCESS_TOKEN_REFRESH_SUCCESS) {
    let newHeaders = getAuthHeaders(authTokenResponse.data.access_token); // new headers based on new token
    headers = {
      ...headers, 
      ...newHeaders
    }
    setAppStateOnLoggedIn(authTokenResponse, dispatch, user);
    switch(method){
      case 'GET' : {
        data = await get(url, headers, dispatch, user);
        break;
      }
      case 'POST':{
        data = await post(url, headers, body, dispatch, user);
        break;
      }
      case 'PUT' :{
        data = await put(url, headers, body, dispatch, user);
        break;
      }
      case 'DELETE' :{
        data = await del(url, headers, body, dispatch, user);
        break;
      }
      default:{
        data = await get(url, headers, dispatch, user);
        break;
      }
    }
    
  }else{
    setAppStateOnLoggedOut(dispatch)
  }
  return data;

// let _response = await fetch(url, params)
}

export const retryIfNeeded = async (data,user,url,method,headers,body, dispatch)=>{
  let isAuthorized = isUserAuthorized(data);
  if(!isAuthorized && user){
    let _data= await retryApiWithNewTokens(url,method, headers, body, dispatch, user)
    if(_data){
      data = _data;
      isAuthorized = isUserAuthorized(data);
    }
  }
  if(!isAuthorized){
    setAppStateOnLoggedOut(dispatch)
  }
  return data;
}