import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGIN_STATE } from "../utils/Constants";


export const ProtectedRoutes = (props) => {
  const user = useSelector((state) => state.user)
  let navigate = useNavigate();
  useEffect(()=>{
    if(user.loginState===LOGIN_STATE.no || user.loginState===''){
        navigate("/", { replace: true });
      }
  },[user])
  return <div>{props.children}</div>;
};
