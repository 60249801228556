import * as React from "react";
import { useState } from 'react'
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import LockResetSharpIcon from '@mui/icons-material/LockResetSharp';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { STATUS_CODE_SUCCESS } from "../../utils/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../services/AuthService";
import { RESET_PASSWORD_INVALID_CREDS_MESSAGE, RESET_PASSWORD_VALID_CREDS_MESSAGE } from "../../utils/StringConstants";
import { enqueueSnackbar } from "notistack";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import { BoxComponent } from "../../common/BoxComponent";

const styles = {
    containerStyle: {
        height:'80vh'
    }
}


export default function ResetPassword() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()]).{8,}/;

        if (!confirmPassword) {
            enqueueSnackbar("Please confirm the password", { autoHideDuration: 1500, variant: "error" })
        } else if (password !== confirmPassword) {
            enqueueSnackbar("Passwords do not match", { autoHideDuration: 1500, variant: 'error' })
        } else if (!passwordRegex.test(password)) {
            enqueueSnackbar("Password must at be least 8 characters long and contain at least 1 uppercase letter, lowercase letter, special character and a number ", { autoHideDuration: 4000, variant: "error" });
        } else {
            let resetPasswordResponse = await resetPassword(token, password, confirmPassword);
            
            if (resetPasswordResponse.statusCode === STATUS_CODE_SUCCESS) {
                enqueueSnackbar(RESET_PASSWORD_VALID_CREDS_MESSAGE, { autoHideDuration: 1500, variant: 'success' })
                navigate("/")
            } else {
                enqueueSnackbar(RESET_PASSWORD_INVALID_CREDS_MESSAGE, { autoHideDuration: 1500, variant: 'error' })
            }
        }
    }

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }
    return (
        
            <Container component="main" maxWidth="xs" style={styles.containerStyle}>
                <CssBaseline />
                <BoxComponent
                >
                    <Avatar variant="avatar-blue">
                        <LockResetSharpIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            autoComplete="password"
                            autoFocus
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toogle password visibility"
                                            onClick={handlePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            autoComplete="current-password"
                            value={confirmPassword}
                            onChange={(e) => setconfirmPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toogle confirm password visibility"
                                            onClick={handleConfirmPasswordVisibility}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="blue-wrap-text"
                        >
                            Submit
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="    /" variant="body2">
                                    Back to Sign-in?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </BoxComponent>
            </Container>
        
    )
}