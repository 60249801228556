import React from "react";

export const BoxComponent = (props) => {
    const boxStyle = {
        marginTop: '10%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom : '10%',
    };

    return(
        <div style={boxStyle}>
            {props.children}
        </div>
    )
}