import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { KEYCLOAK_TOKEN_INVALIDATION_PATH, LOGIN_STATE } from "../utils/Constants";
import { del } from "../utils/ApiUtils";
import { ROUTES } from "../setup/Routes";

export const useAppLogout = ()=>{
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const query = useLocation().pathname;
    const user = useSelector((state) => state.user);
    let [shouldLogout, setShouldLogout] = useState(false);
    const redirectOnLoggedOut = () => {
        for (let i = 0; i < ROUTES.length; i++) {
            let route = ROUTES[i];
            let _path = route.path + '/';
            if (_path.includes(query) && route.isProtectedRoute) {
                console.error("User accessing protected route, sending back to signin")
                navigate("/", { replace: true });
                break;
            }
        }
    }

    const setAppOnLogout = () => {
        dispatch({
            type: "user/setUser",
            payload: {
                email: "",
                refreshToken: "",
                accessToken: "",
            },
        });
        dispatch({
            type: "user/setUserLoginState",
            payload: { loginState: LOGIN_STATE.no },
        });
        redirectOnLoggedOut();
    }

    const logoutUser = async () => {
        try {
            await del(process.env.REACT_APP_SERVER_URL +
                KEYCLOAK_TOKEN_INVALIDATION_PATH,
                {
                    "Content-Type": "application/json"
                },
                {
                    "token": user.refreshToken
                },
                dispatch
            );
        } finally {
            setAppOnLogout();
        }
    }

    useEffect(()=>{
        if(shouldLogout){
            logoutUser()
            setShouldLogout(false);
        }
        
    },[shouldLogout])

    return {
        shouldLogout:  shouldLogout,
        setShouldLogout :  setShouldLogout
    }
}