import * as React from "react";
import {useState} from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { LOGIN_STATE, STATUS_CODE_SUCCESS } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { SIGN_IN_INVALID_CREDS_MESSAGE } from "../../utils/StringConstants";
import { enqueueSnackbar } from "notistack";
import { BoxComponent } from "../../common/BoxComponent"
import { decodeToken } from "react-jwt";
import {setRole} from "../../store/reducers/role" 

const styles = {
  containerStyle: {
      height : '80vh'
  }
}

export default function Signin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let appResponse = await login(data.get("email"), data.get("password"));
    if (appResponse.statusCode === STATUS_CODE_SUCCESS) {
      const accessToken = appResponse.data.access_token;
      const decodedToken = decodeToken(accessToken);
      const isCommandCenterRole = decodedToken.realm_access.roles.includes('command_center');
    
      dispatch(setRole(isCommandCenterRole));

      dispatch({
        type: "user/setUser",
        payload: {
          email: data.get("email"),
          refreshToken: appResponse.data.refresh_token,
          accessToken: appResponse.data.access_token,
          accessTokenExpiry: appResponse.data.expires_in,
          refreshTokenExpiry: appResponse.data.refresh_expires_in,
        },
      });
      dispatch({
        type: "user/setUserLoginState",
        payload: {
          loginState: LOGIN_STATE.yes
        },
      });
      navigate("/dashboard");
    } else {
      enqueueSnackbar(SIGN_IN_INVALID_CREDS_MESSAGE, { autoHideDuration: 1000, variant: 'error' })
    }
  };

  return (
    <Container component="main" maxWidth="sm" style={styles.containerStyle}>
      <CssBaseline />
      <BoxComponent  >
        <Avatar variant="avatar-blue">
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate  
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="User Name"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="blue-wrap-text"
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="./forgotPassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </BoxComponent>
    </Container>
  );
}
