import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { STATUS_CODE_SUCCESS } from "../../utils/Constants";
import { forgotPassword } from "../../services/AuthService";
import { FORGOT_PASSWORD_INVALID_CREDS_MESSAGE, FORGOT_PASSWORD_VALID_CREDS_MESSAGE } from "../../utils/StringConstants";
import { enqueueSnackbar } from "notistack";
import { useStyles } from "../../styles/Styles";
import { BoxComponent } from "../../common/BoxComponent";

export default function ForgotPassword() {
    const styles = useStyles()
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let appResponse = await forgotPassword(data.get("email"));

        if (appResponse.statusCode === STATUS_CODE_SUCCESS) {
            enqueueSnackbar(FORGOT_PASSWORD_VALID_CREDS_MESSAGE, { autoHideDuration: 2000, variant: 'success' })
        }
        else {
            enqueueSnackbar(FORGOT_PASSWORD_INVALID_CREDS_MESSAGE, { autoHideDuration: 2000, variant: 'error' })
        }
    }


    return (

        <Container component="main" style={{height:'80vh'}} maxWidth="xs" >
            <CssBaseline />
            <BoxComponent
            >
                <Avatar className={styles.avatar} >
                    <MarkEmailReadIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Verification
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="blue-wrap-text"
                    >
                        Verify
                    </Button>
                </Box>
            </BoxComponent>
        </Container>

    )
}