import { retryIfNeeded } from "../services/AuthService";

// send user as parameter for retrying for new access token if expired
export const get = async (url, headers, dispatch, user) => {
  let response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  let data = await response.json();
  data = await retryIfNeeded(data,user,url,'GET',headers,null,dispatch);
  return data;
};

export const post = async (url, headers, body, dispatch, user) => {
  let response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: await JSON.stringify(body),
  });
  let data = await response.json();
  // data = await response.json();
  data = await retryIfNeeded(data,user,url,'GET',headers,null,dispatch);
  return data;
};

export const put = async (url, headers, body, dispatch, user) => {
  let response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body: await JSON.stringify(body),
  });
  let data = await response.json();
  data = await retryIfNeeded(data,user,url,'GET',headers,null,dispatch);
  return data;
};

export const del = async (url, headers, body, dispatch, user) => {
  let response = await fetch(url, {
    method: "DELETE",
    headers: headers,
    body: await JSON.stringify(body),
  });
  let data = await response.json();
  data = await retryIfNeeded(data,user,url,'GET',headers,null,dispatch);
  return data;
};

